<script>
import AppStepper from "../atoms/AppStepper.vue";
import AppDialog from "@/components/atoms/AppDialog.vue";
import TheInnerToolbar from "@/components/organisms/TheInnerToolbar.vue";
import expenseEditForm from "@/constants/form/expense-edit-form";

export default {
  name: "ExpenseEditTemplate",
  components: { AppStepper, AppDialog, TheInnerToolbar },
  data: () => ({
    dialog: false,
    step: 1,
    form: {},
    formName: "ExpenseEdit",
    formKeys: {
      1: "3",
    },
    stepList: JSON.parse(JSON.stringify(expenseEditForm)),
  }),
  props: {
    originalExpense: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // if (this.hasExpense && this.step === 1) {
    //   this.step = 2;
    // }
  },
  computed: {
    hasExpense: function () {
      return (
        this.$store.state.billing.billingList.filter(
          (billing) => billing.type != "expense"
        ).length > 0
      );
    },
  },
  mounted() {
    this.populateForm();
  },
  methods: {
    changeStep(payload) {
      this.step = payload;
    },
    handleChange(payload) {
      let keys = Object.keys(this.stepList);
      keys.forEach((key) => {
        if (this.formKeys[key] != undefined) {
          let fieldKeys = Object.keys(
            this.stepList[key][this.formKeys[key]].form
          );
          fieldKeys.forEach((field) => {
            this.stepList[key][this.formKeys[key]].form[field].value =
              payload[this.stepList[key][this.formKeys[key]].name][field];
          });
        }
      });
    },
    populateForm() {
      let keys = Object.keys(this.stepList[1][3].form);
      keys.forEach((key) => {
        this.stepList[1][3].form[key].value = this.originalExpense[key];
      });
    },
    cancelConfirm() {
      this.dialog = false;
      this.$emit("cancel");
    },
    cancelCancel() {
      this.dialog = false;
    },
    cancel() {
      this.dialog = true;
    },
    submit(payload) {
      this.$emit("submit", payload["expense-edit"]);
    },
  },
};
</script>

<template>
  <section class="inner-form section-container">
    <the-inner-toolbar title="Editar Despesa" :isForm="true" @cancel="cancel" />

    <app-stepper
      @changeStep="changeStep"
      :currentStep="step"
      :stepList="stepList"
      :formKeys="formKeys"
      @change="handleChange"
      @cancel="cancel"
      @submit="submit"
    />
    <app-dialog
      :dialog="dialog"
      title="Confirmação"
      text="Tem certeza que deseja cancelar? Os dados editados serão perdidos."
      buttonCancel="Voltar"
      buttonConfirm="Confirmar"
      @clickConfirm="cancelConfirm"
      @clickCancel="cancelCancel"
    />
  </section>
</template>

<style lang="scss" scoped>
.form-container {
  height: 100%;
  width: 100%;
}

.page-subtitle {
  text-align: center;
  font-weight: 500;
  width: 80%;
  margin-bottom: 3.5em !important;
  font-size: 14px;
  margin: 0 auto;
  color: #757575;
}
</style>
