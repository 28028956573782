<script>
import ExpenseEditTemplate from "../templates/ExpenseEditTemplate.vue";
import { mapGetters } from "vuex";

export default {
  components: { ExpenseEditTemplate },
  name: "ExpenseEditPage",
  data: () => ({
    form: {},
  }),
  props: {
    id: String,
    billingId: String,
  },
  computed: {
    originalExpense: function () {
      return this.$store.state.billing.billingList.filter(
        (billing) => billing.id === this.billingId
      )[0];
    },
    ...mapGetters({ currentAcademy: "academy/currentAcademy" }),
  },
  methods: {
    async submit(payload) {
      let updateBilling = {
        date: payload.date,
        reference: payload.reference,
        method: payload.method,
        price: payload.price,
        description: payload.description,
        academyId: this.currentAcademy.id,
      };
      await this.$store.dispatch("billing/update", {
        id: this.billingId,
        billing: updateBilling,
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<template>
  <expense-edit-template
    :originalExpense="originalExpense"
    @cancel="cancel"
    @submit="submit"
  />
</template>
